import { useEffect } from 'react'

function Chat() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modelName = urlParams.get("model");

    if (modelName === "ft:gpt-3.5-turbo-0613:megamind-tech:mega-t4:8AHr6elN") {
      window.location.href = "./Martello";
    }
    else if (modelName === "ft:gpt-3.5-turbo-1106:megamind-tech:abzo-v2:8R2Ufm3L") {
      window.location.href = "./Abbozzo";
    }
    else {
      window.location.href = "./";
    }
  }, []);
}

export default Chat;