import { useState, useEffect } from 'react'
import '../App.css'
import { OpenAI } from 'openai'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { ChatContainer, MessageList, Message, MessageInput, TypingIndicator, ConversationHeader } from '@chatscope/chat-ui-kit-react';


let systemMessage;
let model;
// "ft:gpt-3.5-turbo-0613:megamind-tech:mega-t4:8AHr6elN"

let openaiApiKey = "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";

const openai = new OpenAI({
  apiKey: openaiApiKey,
  dangerouslyAllowBrowser: true
});

const userId = 'user_' + Date.now() + '_' + Math.random().toString(36).substr(2, 9);

function Chat() {
  const [messages, setMessages] = useState([
    {
      message: "Hello, How can I help you?",
      sentTime: "just now",
      sender: "YOBO"
    }
  ]);
  const [isTyping, setIsTyping] = useState(false);

  model = "ft:gpt-3.5-turbo-0613:megamind-tech:mega-t4:8AHr6elN";
  systemMessage = {
    "role": "system", "content": "You will answer every question very fast. Your name is Yobo. You are not gpt. You will say nothing related to gpt or chatgpt in your introduction. You are a customer service bot named Yobo at Martello Alley, an art gallery in Kingston, Ontario. You were made by MegaMind AI- a company based in Brampton, Ontario. Megamind makes AI powered workplace assistants to automate repetitive tasks. Each of your responses should be concise. Each response must be less than 35 words. If you need to say more, you can ask the user if they want you to continue. Always try to have a conversation. Encourage the user to ask more questions about Martello Alley or suggest relevant questions. Never ever introduce yourself as gpt or chatgpt or openai. You're not made by openai. You're made by megamind and megamind only. You're a customer service bot. You're not an AI model. If any questions are not related to Martello Alley or Megamind, You can answer the questions fast on your own. You can also get your answers from https://www.martelloalley.com. Learn from the conversation and your own answers. When a question is asked for the second time, answer from your previous experience in a fast and concise manner."
  }

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      // console.log(window.innerHeight, window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  });

  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user"
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);
    setIsTyping(true);
    await processMessage(newMessages);
  };

  async function processMessage(chatMessages) {

    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "user") {
        role = "user";
      } else {
        role = "assistant";
      }
      return { role: role, content: messageObject.message }
    });

    try {
      const response = await openai.chat.completions.create({
        model: model,
        messages: [systemMessage, ...apiMessages],
      });
      setMessages([...chatMessages, {
        message: response.choices[0].message.content,
        sender: "YOBO"
      }]);
      setIsTyping(false);
      return response.json();

    }
    catch (error) {
      console.log(error);
    }
  }


  const URLify = (message, direction) => {
    const urlRegex = /(\b(https?:\/\/)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*)?\b)/g;
    return message.replace(urlRegex, (url) => {
      // check if the protocol is present, if not, prepend http://
      const completeUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;
      if (direction === "outgoing") {
        return `<a class="outTextURL" href="${completeUrl}" target="_blank">${url}</a>`;
      }
      return `<a class="inTextURL" href="${completeUrl}" target="_blank">${url}</a>`;
    });
  };

  const saveChatLog = async (chatMessages) => {
    try {
      await fetch('https://yobo.megamindtech.com/logger/saveChat.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId: userId, messages: chatMessages })
      });
    } catch (error) {
      console.error('Error saving chat log:', error);
    }
  };

  // useEffect hook to save the chat log every time a new message is added
  useEffect(() => {
    if (messages.length > 0) {
      saveChatLog(messages);
    }
  }, [messages]);

  return (
    <ChatContainer className="App" style={{ height: dimensions.height }}>
      <ConversationHeader>
        <ConversationHeader.Content>
          <div>
            <img src="./logo.png" alt="logo" style={{ width: "auto", height: "50px" }} />
            <p><span>AI</span></p>
          </div>
        </ConversationHeader.Content>
      </ConversationHeader>
      <MessageList
        scrollBehavior="auto"
        typingIndicator={isTyping ? <TypingIndicator content="YOBO is typing" /> : null}
      >
        {messages.map((message, i) => (
          <Message key={i} model={{ ...message, message: URLify(message.message, message.direction) }} />
        ))}
      </MessageList>
      <MessageInput placeholder="Type message here" onSend={handleSend} attachButton={false} />
    </ChatContainer>
  );
}

export default Chat;